import React, { createContext, useContext, useEffect, useState } from "react"

const defaultContext = {
  counter: 0,
  suppliers: [],
  entry: true,
}

export const Context = createContext(defaultContext)
export const useAuth = () => useContext(Context)

const ProviderWrapper = ({ children }) => {
  const [counter, updateCounter] = useState(0)
  const [suppliers, updateSuppliers] = useState([])

  const [entry, showEntry] = useState(true)

  useEffect(() => {
    updateCounter(
      JSON.parse(window.localStorage.getItem("selected_suppliers"))
        ? JSON.parse(window.localStorage.getItem("selected_suppliers")).length
        : 0
    )

    updateSuppliers(
      JSON.parse(window.localStorage.getItem("selected_suppliers"))
        ? JSON.parse(window.localStorage.getItem("selected_suppliers"))
        : []
    )
  }, [])

  return (
    <Context.Provider
      value={{
        counter,
        updateCounter,
        suppliers,
        updateSuppliers,
        entry,
        showEntry,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default ProviderWrapper
